import Vue from 'vue'
import { METHODS, NOTIFY_STATUS } from '@/const'
import ERRORS from '@/store/errors'

const vue = Vue.prototype

const actions = {
	async getFeatures ({ rootState, commit }, input) {
		commit('setFeatures', {
			items: [],
			total: 0
		})
		try {
			const result = await rootState.request({
				type: METHODS.GET,
				apiName: 'hqswApi',
				url: '/features',
				auth: true,
				query: input.query
			})
			if (result && result.has_data && result.data && result.data.items && Array.isArray(result.data.items)) {
				commit('setFeatures', {
					items: result.data.items,
					total: result.data.total || 0
				})
			} else {
				throw new Error(ERRORS.GET_DATA)
			}
		} catch ({ message }) {
			vue.$notify({
				type: NOTIFY_STATUS.ERROR,
				title: message || ERRORS.GET_DATA
			})
		}
	},
	async createFeature ({ rootState }, body) {
		try {
			const result = await rootState.request({
				type: METHODS.POST,
				apiName: 'hqswApi',
				url: '/features',
				auth: true,
				body
			})
			if (result && result.status === 201 && result.data && result.data.success) {
				vue.$notify({
					type: NOTIFY_STATUS.SUCCESS,
					title: 'Фича добавлена'
				})
				return true
			} else {
				throw new Error(ERRORS.CREATE)
			}
		} catch (e) {
			const errorsBackend = e &&
				e.body &&
				e.body.data &&
				e.body.data.errors &&
				typeof e.body.data.errors === 'object' &&
				Object.values(e.body.data.errors)
			vue.$notify({
				type: NOTIFY_STATUS.ERROR,
				title: (errorsBackend && errorsBackend[0]) || ERRORS.CREATE
			})
			return false
		}
	},
	async updateFeature ({ rootState, commit }, input) {
		try {
			const result = await rootState.request({
				type: METHODS.PUT,
				apiName: 'hqswApi',
				url: `/features/${input.id}`,
				auth: true,
				body: input.body
			})
			if (result && result.status === 200 && result.data && result.data.success) {
				vue.$notify({
					type: NOTIFY_STATUS.SUCCESS,
					title: 'Фича обновлена'
				})
				commit('updateFeature', input)
			} else {
				throw new Error(ERRORS.UPDATE)
			}
		} catch (e) {
			const errorsBackend = e &&
				e.body &&
				e.body.data &&
				e.body.data.errors &&
				typeof e.body.data.errors === 'object' &&
				Object.values(e.body.data.errors)
			vue.$notify({
				type: NOTIFY_STATUS.ERROR,
				title: (errorsBackend && errorsBackend[0]) || ERRORS.UPDATE
			})
		}
	},
	async deleteFeature ({ rootState }, id) {
		try {
			const result = await rootState.request({
				type: METHODS.DELETE,
				apiName: 'hqswApi',
				url: `/features/${id}`,
				auth: true
			})
			if (result && result.status === 200 && result.data && result.data.success) {
				vue.$notify({
					type: NOTIFY_STATUS.SUCCESS,
					title: 'Фича удалена'
				})
				return true
			} else {
				throw new Error(ERRORS.DELETE)
			}
		} catch ({ message }) {
			vue.$notify({
				type: NOTIFY_STATUS.ERROR,
				title: message || ERRORS.DELETE
			})
			return false
		}
	}
}

export default actions
