import constants from '@/const/'
import errors from '@/store/errors'

const actions = {
	getClients ({ commit, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'seowork',
				url: '/clients',
				auth: true,
				query: input
			}).then(result => {
				commit('pushClients', result)
				resolve(true)
			}, () => {
				reject(Error(errors.GET_DATA))
			})
		})
	},

	getForceProjects ({ commit, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				url: 'extract/force-update/project/list',
				auth: true,
				query: input
			}).then(result => {
				commit('pushForceProjects', result)
				resolve(true)
			}, () => {
				reject(Error(errors.GET_DATA))
			})
		})
	},

	getForceProjectHistory ({ commit, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				url: 'extract/force-update/project/history',
				auth: true,
				query: input
			}).then(result => {
				commit('pushForceProjectHistory', result)
				resolve(true)
			}, () => {
				reject(Error(errors.GET_DATA))
			})
		})
	},
	postForceUpdate: async function ({ state, commit, getters, rootState }, input) {
		try {
			const res = await rootState.request({
				type: constants.METHODS.POST,
				apiName: 'hqsw',
				url: 'extract/force-update/project/create',
				auth: true,
				query: input,
				body: input
			})
			if (res.status >= 200 && res.status < 300) {
				return Promise.resolve(true)
			}
		} catch (e) {
			return Promise.reject(e.body.error_messages[400])
		}
	}

}

export default actions
