import Vue from 'vue'

const mutations = {
	addSegmentsCount (state, data) {
		Vue.set(state, 'segmentsCount', data)
	},
	addProjectSegmentsCount (state, data) {
		Vue.set(state, 'projectSegmentsCount', data)
	},
	addProjectSegmentsTotal (state, data) {
		Vue.set(state, 'projectSegmentsTotal', data)
	},
	addProblematicSegments (state, data) {
		Vue.set(state, 'problematicSegments', data)
	},
	addProblematicTotal (state, data) {
		Vue.set(state, 'problematicTotal', data)
	}
}

export default mutations
