import { getUser } from '@/function/auth.js'

const accessAside = item => {
	/* Права на отображения */
	const userData = getUser()
	switch (item.access) {
	case 'r':
	case 'w':
		return true
	case 'd':
		return userData.isRoot()
	default:
		return false
	}
}

const getters = {
	asideData: state => input => {
		/* Пренудительно отображаемые разделы */
		const view_routes = []

		if (state.aside) {
			let aside
			const projectId = state.active_project

			let base_menu
			let settings_menu
			let help_menu

			if (input && input.dom) {
				aside = JSON.parse(JSON.stringify(input.dom))
			} else {
				aside = JSON.parse(JSON.stringify(state.aside))
			}

			/* Метод для манипуляций с роутами */
			const findRoute = list => list.map(item => {
				if (item.type === 'list') {
					/* Проведение манипуляция со списками роутов */
					item.items = findRoute(item.items)
					item.actual_action = item.items.some(item => item.actual_action)
					item.spa_actual_action = item.items.some(item => item.spa_actual_action)

					return {
						...item,
						view: accessAside(item)
					}
				} else if (item.type === 'route') {
					/* Проведение манипуляция с роутами */
					if (state.config.dev || item.is_spa) {
						item.to = input && input.staticRouter ? item.spa_path : { ...item.spa_route }
						if (item.to && item.to.params && item.to.params.activeProject) {
							item.to.params.activeProject = projectId || item.params.projectId
						}
					} else {
						let url = item.url
						if (url) {
							for (const i in item.params) {
								switch (i) {
								case 'project_id':
								case 'projectId':
									url = url.replace(`{${i}}`, projectId || item.params[i])
									break

								default:
									url = url.replace(`{${i}}`, item.params[i])
									break
								}
							}
						}

						if (input && input.staticRouter) {
							item.to = url
						} else {
							item.to = {
								name: 'redirect',
								query: {
									q: url
								}
							}
						}
					}

					if (item.spa_route && item.spa_route.name && (view_routes.indexOf(item.spa_route.name) !== -1 || state.config.dev)) {
						item.forwardView = true
					}

					/*
							   * Выделение уже переведенных страниц
							   * ToDo: Удалить после перехода
							   */
					item.marker = !!(state.config.dev && item.is_spa)

					/* Управление выделением пунктов меню */
					if (
						input &&
						input.mapRouter &&
						!input.staticRouter &&
						(item.is_spa || state.config.dev) &&
						item.to.name &&
						input.mapRouter.indexOf(item.to.name) !== -1
					) {
						item.actual_action = true
						item.spa_actual_action = true
					}

					return {
						...item,
						view: accessAside(item)
					}
				} else {
					return {
						...item,
						view: accessAside(item)
					}
				}
			})

			/* Основные разделы меню */
			if (aside.base_menu && aside.base_menu.length) {
				base_menu = findRoute(aside.base_menu)
			}

			/* Меню настроек */
			if (aside.bottom_menu && Array.isArray(aside.bottom_menu) && aside.bottom_menu[0]) {
				settings_menu = [{
					...aside.bottom_menu[0],
					items: findRoute(aside.bottom_menu[0].items),
					view: accessAside(aside.bottom_menu[0])
				}]
			}

			/* Меню Помощь */
			if (aside.bottom_menu && Array.isArray(aside.bottom_menu) && aside.bottom_menu[1]) {
				help_menu = [{
					...aside.bottom_menu[1],
					items: findRoute(aside.bottom_menu[1].items),
					view: accessAside(aside.bottom_menu[1])
				}]
			}

			return {
				base_menu,
				settings_menu,
				help_menu,
				view_routes
			}
		} else {
			return {}
		}
	}
}

export default getters
