import Vue from 'vue'
import VueCookie from 'vue-cookie'

/**
 * Компонент меню
 */
import item from './item.vue'
import help from './help.vue'
import slideMenu from './slideMenu.vue'

import constants from '@/const/'

Vue.use(VueCookie)
const Cookies = Vue.cookie

export default {
	data () {
		return {
			/* Отображение узкого меню */
			menuNarrow: true,

			/* Флаг отображение тегов */
			flagViewTags: false,

			/* Активные списки */
			isActive: {},

			/* Флаг отображения меню настроек */
			menuHelp: false,

			/* Флаг отображения меню настроек */
			menuSettings: false,

			/* Флаг тестового период для Main */
			testPeriod: false
		}
	},

	components: {
		item: item,
		help: help,
		'slide-menu': slideMenu
	},

	computed: {
		/* Дерево роутов */
		mapRouter () {
			return this.$route && this.$route.matched.map((item) => item.name).reverse()
		},

		/* Список разделов меню */
		asideData () {
			return this.$store.getters.asideData({
				mapRouter: this.mapRouter
			})
		}
	},

	mounted () {
		this.getFlagMenu()
		document.addEventListener('keydown', this.keydownEscape)
	},

	destroyed () {
		document.removeEventListener('keydown', this.keydownEscape)
	},

	methods: {
		/* Открытие модального окна Intercom */
		interComstartTour (modalId) {
			// Intercom('startTour', modalId)
		},

		/* Получение флага отображения меню */
		getFlagMenu () {
			// const refreshData = Cookies.get(constants.LOCAL_STORAGE_DATA.LOCKER_MENU)
			if (Cookies.get(constants.LOCAL_STORAGE_DATA.LOCKER_MENU) === 'true') {
				this.menuNarrow = false
			}
			this.$emit('changeMenuWidth', this.menuNarrow)
		},

		/* Переключение режима меню */
		menuSlideToggle () {
			this.menuNarrow ^= true
			Cookies.set(constants.LOCAL_STORAGE_DATA.LOCKER_MENU, !this.menuNarrow)
			this.$emit('changeMenuWidth', this.menuNarrow)
		},

		/* Установка активного пункта меню */
		updateActiveRoute (data) {
			this.$set(this.isActive, data.level, this.isActive[data.level] !== data.index || data.fast ? data.index : null)
		},

		/* Метод при нажатии на Escape */
		keydownEscape (event) {
			if (event.code === 'Escape') {
				if (this.menuHelp) {
					this.toggleHelpMenu()
				}
				if (this.menuSettings) {
					this.toggleSettingsMenu()
				}
			}
		},

		/* Отображение меню помощь */
		toggleHelpMenu (flag) {
			if (flag) {
				this.menuHelp ^= true
			} else {
				this.menuHelp = Boolean(flag)
			}
		},

		/* Отображение меню настроек */
		toggleSettingsMenu (flag) {
			if (flag) {
				this.menuSettings ^= true
			} else {
				this.menuSettings = Boolean(flag)
			}
		}
	}
}
