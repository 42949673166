import constants from '@/const/'

const actions = {
	getSavedData: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/ws/saved',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addSavedData', result.data)
					resolve(true)
				} else {
				}
			}, () => {
			})
		})
	},
	getConfig: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/ws/config',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addConfig', result.data)
					resolve(true)
				} else {
				}
			}, () => {
			})
		})
	}
}

export default actions
