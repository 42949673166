<template>
	<li v-if="data.view || typeIntercom" :class="classList">
		<!-- Список -->
		<template v-if="typeList">
			<popper
				v-if="menuNarrow"
				trigger="hover"
				:visible-arrow="false"
				placement="right-start"
				:options="{
                    placement: 'right-start',
                    positionFixed: true,
                    modifiers: {
                        preventOverflow: {
                            boundariesElement: 'viewport'
                        }
                    }
                }"
			>
				<ul class="aside-item__internal popper">
					<span class="aside-item__internal-title">{{ data.title }}</span>
					<item
						v-for="(item, i) in data.items"
						:data="item"
						:key="i"
						:index="i"
						:isActive="isActive"
						:flagViewTags="flagViewTags"
						:level="level + 1"
						@updateList="updateList($event)"
						:menuNarrow="true"
						:staticRouter="staticRouter"
					/>
				</ul>

				<span
					slot="reference"
					:class="['aside-item__title', { '_actual': isActualExcretion }]"
					@click="listToggle"
				>
                    <icon v-if="data.icon" class="aside-item__icon" :name="data.icon"></icon>
                    <icon v-else :class="[ 'aside-item__arrow', { '_rotate': isActiveList } ]" name="arrow_right"></icon>
                    <span class="aside-item__name">{{ data.title }}</span>
                    <component :is="componentTag" :data="data"/>
                </span>
			</popper>

			<template v-else>
                <span :class="['aside-item__title', { '_actual': isActualExcretion }]" @click="listToggle">
                    <icon v-if="data.icon" class="aside-item__icon" :name="data.icon"></icon>
                    <icon v-else :class="[ 'aside-item__arrow', { '_rotate': isActiveList } ]" name="arrow_right"></icon>
                    <span class="aside-item__name">{{ data.title }}</span>
                    <component :is="componentTag" :data="data"/>
                </span>

				<transition
					@before-enter="beforeEnter"
					@after-enter="afterEnter"
					@before-leave="beforeLeave"
					@after-leave="afterLeave"
				>
					<ul v-show="isActiveList" ref="list" class="aside-item__internal">
						<span class="aside-item__internal-title">{{ data.title }}</span>
						<item
							v-for="(item, i) in data.items"
							:data="item"
							:key="i"
							:index="i"
							:isActive="isActive"
							:flagViewTags="flagViewTags"
							:level="level + 1"
							@updateList="updateList($event)"
							:staticRouter="staticRouter"
						/>
					</ul>
				</transition>
			</template>
		</template>

		<!-- Intercom виджет -->
		<template v-if="typeIntercom">
			<button class="aside-item__link" @click="interComstartTour()">
				<icon v-if="data.icon" class="aside-item__icon" :name="data.icon"></icon>
				<span class="aside-item__name">{{ data.title }}</span>
				<component :is="componentTag" :data="data"/>
			</button>
		</template>

		<template v-if="typeRoute">
			<!-- TODO: -->
			<!-- ПИЗДЕЦ! ОЧЕНЬ ЖЕСТКИЙ КОСТЫЛЬ ДЛЯ МЕЙНОВСКОЙ! -->
			<!-- В АТРИБУТ "href" НЕОБХОДИМО ВЕРНУТЬ "data.to" -->
			<!-- ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ -->
			<a
				v-if="staticRouter"
				:class="['aside-item__link', { 'router-link-exact-active': isActualExcretion }]"
				:href="staticHref"
				v-tippy="typpyStyle"
				ref="tippyItem"
			>
				<!-- ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ -->
				<icon v-if="data.icon" class="aside-item__icon" :name="data.icon"></icon>
				<span class="aside-item__name">{{ data.title }}</span>
				<component :is="componentTag" :data="data"/>
			</a>

			<router-link
				v-else-if="data.to"
				:class="['aside-item__link', { 'router-link-exact-active': isActualExcretion }, { '_marker': data.marker }]"
				:to="data.to"
				v-tippy="typpyStyle"
				ref="tippyItem"
			>
				<icon v-if="data.icon" class="aside-item__icon" :name="data.icon"></icon>
				<span class="aside-item__name">{{ data.title }}</span>
				<component :is="componentTag" :data="data"/>
			</router-link>
		</template>

		<!-- Событие -->
		<template v-if="typeEvent && data.event">
			<button
				class="aside-item__link"
				@click="data.event"
				v-tippy="typpyStyle"
				ref="tippyItem"
			>
				<icon v-if="data.icon" class="aside-item__icon" :name="data.icon"></icon>
				<span class="aside-item__name">{{ data.title }}</span>
				<component :is="componentTag" :data="data"/>
			</button>
		</template>
	</li>
</template>

<script>
/* Компонент тега */
import Popper from 'vue-popperjs'

const componentTagRender = function (data) {
	return {
		template: `
                <span v-if="data.tagtext" class="aside__tagtext" :style="textStyle">
                    {{ data.tagtext.text }}
                </span>

                <span v-else-if="data.tagicon" class="aside__icontag" v-tippy="typpyStyle" :content="iconText" ref="icontag">
                    <icon :name="data.tagicon.icon"></icon>
                </span>
            `,

		props: {
			data: {
				type: Object
			}
		},

		data () {
			return {}
		},

		computed: {
			typpyStyle () {
				return {
					popperOptions: {
						modifiers: {
							preventOverflow: {
								enabled: false
							},
							hide: {
								enabled: false
							}
						}
					}
				}
			},

			iconText () {
				try {
					return this.data.tagicon.text
				} catch (_) {
					return false
				}
			},

			textStyle () {
				if (this.data.tagtext) {
					return `background-color: ${this.data.tagtext.color}`
				}
			}
		},

		watch: {
			iconText (val) {
				this.updateView()
			}
		},

		mounted () {
			this.updateView()
		},

		methods: {
			updateView () {
				this.$nextTick(() => {
					if (this.$refs.icontag) {
						if (this.iconText) {
							this.$refs.icontag._tippy.enable()
						} else {
							this.$refs.icontag._tippy.disable()
						}
					}
				})
			}
		}
	}
}

export default {
	name: 'item',

	components: {
		popper: Popper
	},

	data () {
		return {
			/* Переключатель анимации */
			animation: false
		}
	},

	props: {
		/*
				   * Флаг определяющий тип роутинга
				   */
		staticRouter: {
			type: Boolean,
			default: false
		},

		/* Скисок ссылок и разделов */
		data: {
			type: Object,
			required: true,
			default: function () {
				return {}
			}
		},

		/* Уровень погружения */
		level: {
			type: Number,
			default: 1
		},

		/* Индекс элемента */
		index: {
			type: Number
		},

		/* Активные разделы */
		isActive: {
			type: Object
		},

		/* Флаг отображения тегов */
		flagViewTags: {
			type: Boolean,
			default: true
		},

		/* Функция возврата */
		updateList: {
			type: Function,
			default: function (val) {
				this.$emit('updateList', val)
				if (val.level > 1 && this.isActive[val.level - 1] === undefined) {
					this.$emit('updateList', {
						level: this.level,
						index: this.index,
						fast: true
					})
				}
			}
		},

		menuNarrow: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		/* Классы раздела */
		classList () {
			const classes = ['aside-item']

			switch (true) {
			case this.typeList:
				classes.push('_list')
				if (this.isActiveList) {
					classes.push('_active')
				}
				break

			case this.typeRoute:
			case this.typeEvent:
				classes.push('_link')
				break
			}

			return classes
		},

		/* Компонент ссылки */
		componentTag () {
			return componentTagRender(this.data)
		},

		/* Тип: список */
		typeList () {
			return this.data.type === 'list'
		},

		/* Тип: роут */
		typeRoute () {
			return !!(this.data.type === 'route' && (this.data.url || this.data.to))
		},

		/* Тип: событие */
		typeEvent () {
			return !!(this.data.type === 'event' && (this.data.event))
		},

		/* Тип: Intercom */
		typeIntercom () {
			return this.data.type === 'intercom'
		},

		/* Флаг активности списка */
		isActiveList () {
			return !!(this.typeList && this.isActive[this.level] === this.index)
		},

		/* Флаг активности ссылки */
		isActiveRoute () {
			return this.data.actual_action || this.data.actual_module
		},

		/* Флаг выделения пункта */
		isActualExcretion () {
			return this.staticRouter ? this.data.actual_action || this.data.actual_module : this.data.spa_actual_action
		},

		/* Конфигурация */
		config () {
			return this.$store.state.config
		},

		typpyStyle () {
			if (this.level === 1 && (this.typeRoute || this.typeEvent)) {
				return {
					content: this.data.title,
					placement: 'right'
				}
			} else {
				return {
					onShow: () => {
						return false
					}
				}
			}
		},

		/*
				   * TODO:
				   * ПИЗДЕЦ! ОЧЕНЬ ЖЕСТКИЙ КОСТЫЛЬ ДЛЯ МЕЙНОВСКОЙ!
				   * ВЫПИЛИТЬ ЭТУ ХУЙНЮ НАХУЙ, КАК МОЖНО СКОРЕЕ!!!
				   * ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
				   */
		staticHref () {
			if (this.data.is_spa) {
				switch (location.host) {
				case 'seowork.io':
				case 'seowork.ru':
					return `${location.protocol}//spa.${location.host + this.data.to}`
				default:
					return this.data.to
				}
			} else {
				return this.data.to
			}
		}
		/* ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ */
	},

	mounted () {
		if (this.isActualExcretion && this.typeRoute) {
			this.listToggle(null, true)
		}

		this.viewAboutItemTippy(this.menuNarrow)
	},

	watch: {
		menuNarrow (val) {
			this.viewAboutItemTippy(val)
		}
	},

	methods: {
		viewAboutItemTippy (val) {
			this.$nextTick(() => {
				if (this.level === 1 && this.$refs.tippyItem) {
					try {
						const elTippy = this.staticRouter ? this.$refs.tippyItem._tippy : this.$refs.tippyItem.$el._tippy
						if (val) {
							elTippy.enable()
						} else {
							elTippy.disable()
						}
					} catch (_) {
					}
				}
			})
		},

		/* Управление раскрытем списка */
		listToggle (event, fast) {
			this.updateList({
				level: this.level,
				index: this.index,
				fast: fast
			})

			if (!this.animation) {
				this.animation = true
			}
		},

		/* Появление списка */
		beforeEnter (el) {
			if (this.animation) {
				// Setup clone
				const clone = el.cloneNode(true)
				clone.style.width = el.style.width
				clone.style.visibility = 'hidden'
				clone.style.removeProperty('display')

				// Анимация внутренних списков (если таковые существуют)
				const desc = clone.querySelectorAll('*');
				[].forEach.call(desc, function (item) {
					item.style.removeProperty('none')
				})

				el.parentNode.appendChild(clone)
				const h = clone.clientHeight
				clone.remove()

				// Принудительная анимация
				el.style.height = '0px'
				setTimeout(() => {
					el.style.height = h + 'px'
				}, 1)
			}
		},

		afterEnter (el) {
			if (this.animation) {
				el.style.removeProperty('height')
			}
		},

		/* Скрытие списка */
		beforeLeave (el) {
			el.style.height = el.clientHeight + 'px'
			setTimeout(() => {
				el.style.height = '0px'
			}, 1)
		},

		afterLeave (el) {
			el.style.removeProperty('height')
		}
	}
}
</script>

<style lang="scss" src="./aside.scss" scoped></style>
