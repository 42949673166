const getters = {
	todayData: (state) => {
		if (state.savedData) {
			const savedDataCopy = { ...state.savedData }
			const data = []
			const today = new Date().toISOString().substr(0, 10)
			const neededKeys = ['date', 'count_main_all', 'count_main_finish', 'prc_main_finish', 'count_ch_all', 'count_ch_finish', 'prc_ch_finish']

			Object.keys(savedDataCopy).map((key) => {
				const value = savedDataCopy[key]
				if (Array.isArray(value)) {
					const valueData = value.find((el) => el.date === today) ? value.find((el) => el.date === today) : {}

					const intersection = neededKeys.filter(x => !Object.keys(valueData).includes(x))

					for (const item of intersection) {
						valueData[item] = '-'
					}

					data.push({
						key: key,
						...valueData
					})
				}
			})

			return data
		}
		return []
	},

	tabs: (state) => {
		if (state.savedData) {
			const tabsArr = []
			const savedDataCopy = { ...state.savedData }
			Object.keys(savedDataCopy).map((key, idx) => {
				tabsArr.push({
					name: key,
					id: idx
				})
			})
			return tabsArr
		}
		return []
	},

	itemsByTab: (state) => (tab) => {
		const neededKeys = ['date', 'count_main_all', 'count_main_finish', 'prc_main_finish', 'count_ch_all', 'count_ch_finish', 'prc_ch_finish']
		if (state.savedData && state.savedData[tab]) {
			const items = state.savedData[tab].sort((a, b) => new Date(b.date) - new Date(a.date))

			return items.map((item) => {
				const missingValues = {}
				const intersection = neededKeys.filter(x => !Object.keys(item).includes(x))

				for (const missingKey of intersection) {
					missingValues[missingKey] = '-'
				}

				return {
					...item,
					...missingValues
				}
			})
		}
		return []
	}
}

export default getters
