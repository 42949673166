import Vue from 'vue'

const mutations = {
	setSessions (state, data) {
		Vue.set(state, 'total', data.total)
		Vue.set(state, 'sessions', data.sessions)
	},
	setSessionsWS (state, data) {
		Vue.set(state, 'totalWS', data.total)
		Vue.set(state, 'sessionsWS', data.sessions)
	}
}

export default mutations
