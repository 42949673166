import Vue from 'vue'

import { findKey } from '@/function/findOptions'

const mutations = {
	setI18nEntityTypesList (state, data) {
		Vue.set(state, 'i18nEntityTypesList', data)
	},
	setI18nPhrasesList (state, data) {
		Vue.set(state, 'i18nPhrasesList', data)
	},
	setI18nPhrasesPlaces (state, data) {
		Vue.set(state, 'i18nPhrasesPlaces', data)
	},
	setI18nPhrasesTranslatesList (state, data) {
		Vue.set(state, 'i18nPhrasesTranslatesList', data)
	},
	setI18nTranslatesList (state, data) {
		Vue.set(state, 'i18nTranslatesList', data)
	},
	setWizardsGroupList (state, data) {
		Vue.set(state, 'wizardsGroupList', data)
	},
	setWizardsList (state, data) {
		Vue.set(state, 'wizardsList', data)
	},
	setSelectedEntityType (state, data) {
		Vue.set(state, 'selectedEntityType', data)
	},

	deleteEntityType  (state, id) {
		const keyType = findKey(id, state.i18nEntityTypesList.items)
		if (keyType !== undefined) {
			state.i18nEntityTypesList.items.splice(keyType, 1)
		}
	},

	deleteTranslate  (state, id) {
		const keyType = findKey(id, state.i18nTranslatesList.items)
		if (keyType !== undefined) {
			state.i18nTranslatesList.items[keyType].translate = ''
		}
	}
}

export default mutations
