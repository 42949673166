import { mapState } from 'vuex'

export default {
	data () {
		return {
			viewUserMenu: false
		}
	},

	computed: {
		...mapState({
			profile: state => state.profile
		}),

		typpyStyle () {
			return {
				trigger: 'click',
				interactive: true,
				theme: 'profile',
				offset: '7, 16',
				placement: 'bottom-end',

				onShow: () => {
					this.viewUserMenu = true
				},

				onHide: () => {
					this.viewUserMenu = false
				}
			}
		}
	}
}
