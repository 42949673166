const getters = {
	segments: (state) => {
		if (state.segmentsCount) {
			return [state.segmentsCount]
		}
		return []
	},
	transformedSegments: (state) => {
		if (state.segmentsCount) {
			return Object.keys(state.segmentsCount.main).map((el, idx) => {
				return {
					type: el,
					count_main: state.segmentsCount.main[el],
					count_ch: state.segmentsCount.chstat[el]
				}
			})
		}
		return []
	},
	transformedProblematicSegments: (state) => (entity) => {
		if (state.projectSegmentsCount && state.projectSegmentsCount.length) {
			const segments = [...state.projectSegmentsCount]
			let newArr = []

			segments.map((el) => {
				const keys = Object.keys(el.statistics)

				keys.map((key) => {
					const item = el.statistics[key]
					const { diff, ...rest } = el.statistics[key]

					if (diff) {
						diff.query_count_prc = calcDiff(item, 'query_count')
						diff.segment_count_prc = calcDiff(item, 'segment_count')
						diff.url_count_prc = calcDiff(item, 'url_count')
					}

					newArr.push({
						project_id: el.project_id,
						project_name: el.project_name,
						segment_type: key,
						diff: diff,
						...rest
					})
				})
			})
			switch (entity.segmentType) {
			case undefined:
				break
			default:
				newArr = newArr.filter((el) => el.segment_type === entity.segmentType)
				break
			}

			switch (entity.type) {
			case 'default':
				break
			default:
				newArr = newArr.sort(function (a, b) {
					const valueA = a.diff ? a.diff[entity.type] : 0
					const valueB = b.diff ? b.diff[entity.type] : 0

					return !entity.isReverse ? valueB - valueA : valueA - valueB
				})
			}

			return newArr
		}
		return []

		function calcDiff (item, entity) {
			if (item && entity) {
				if (!item.main[entity] && !item.chstat[entity]) {
					return 0
				} else if (!item.main[entity] || !item.chstat[entity]) {
					return 100
				} else {
					const num = ((item.main[entity] - item.chstat[entity]) * 100) / item.main[entity]
					return parseFloat(num.toFixed(2))
				}
			}
			return 0
		}
	},
	sortedProblematicSegments: (state) => (entity) => {
		if (state.problematicSegments && state.problematicSegments.length) {
			const segments = [...state.problematicSegments]
			let newArr = []

			segments.map((el) => {
				const item = el.entity_count
				const { diff, ...rest } = el.entity_count

				if (diff) {
					diff.query_count_prc = calcDiff(item, 'query_count')
					diff.segment_count_prc = calcDiff(item, 'segment_count')
					diff.url_count_prc = calcDiff(item, 'url_count')
				}

				newArr.push({
					project_id: el.project_id,
					project_name: el.project_name,
					segment_id: el.segment_id,
					segment_name: el.segment_name,
					type: el.type,
					entity_count: {
						diff: diff,
						...rest
					}
				})
			})

			switch (entity.segmentType) {
			case undefined:
				break
			default:
				newArr = newArr.filter((el) => el.type === entity.segmentType)
				break
			}

			switch (entity.segment_id) {
			case undefined:
				break
			default:
				newArr = newArr.filter((el) => +el.segment_id === +entity.segment_id)
				break
			}

			switch (entity.type) {
			case 'default':
				break
			default:
				newArr = newArr.sort(function (a, b) {
					const valueA = a.entity_count.diff ? a.entity_count.diff[entity.type] : 0
					const valueB = b.entity_count.diff ? b.entity_count.diff[entity.type] : 0

					return !entity.isReverse ? valueB - valueA : valueA - valueB
				})
			}

			return newArr
		}
		return []

		function calcDiff (item, entity) {
			if (item && entity) {
				if (!item.main[entity] && !item.chstat[entity]) {
					return 0
				} else if (!item.main[entity] || !item.chstat[entity]) {
					return 100
				} else {
					const num = (item.diff[entity] * 100) / item.main[entity]
					return parseFloat(num.toFixed(2))
				}
			}
			return 0
		}
	},
	problematicSegmentsList: (state) => {
		if (state.problematicSegments) {
			const segments = [...state.problematicSegments]
			return segments.map((el) => {
				return {
					value: el.segment_id,
					text: el.segment_name
				}
			})
		}
		return []
	}
}

export default getters
