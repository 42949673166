const getters = {
	forceProjects: (state) => {
		if (state.forceProjects && state.forceProjects.length) {
			return state.forceProjects.map(el => ({
				...el,
				name: el.project_name,
				id: el.project_id
			}))
		}
		return []
	}
}

export default getters
