const state = {
	i18nEntityTypesList: {
		items: [],
		total: 0
	},

	i18nPhrasesTranslatesList: {
		items: [],
		total: 0
	},

	i18nTranslatesList: {
		items: [],
		total: 0
	},
	wizardsGroupList: {
		items: [],
		total: 0
	},
	wizardsList: {
		items: [],
		total: 0
	}
}

export default state
