import Vue from 'vue'
const vue = Vue.prototype

const mutations = {
	addProjects (state, data) {
		Vue.set(state, 'projects', data)
	},
	addUrls (state, data) {
		Vue.set(state, 'urls', data)
	},
	addTasks (state, data) {
		Vue.set(state, 'tasks', data.tasks)
		Vue.set(state, 'total', data.total ? data.total : null)
	},
	addStats (state, data) {
		Vue.set(state, 'stats', data)
	},
	clearTasks (state) {
		Vue.set(state, 'tasks', [])
	},
	clearStats (state) {
		Vue.set(state, 'stats', null)
	},
	showRestartedTasks (state, data) {
		let text = data.result_message

		if (data.not_restarted && data.not_restarted.length) {
			const line = data.not_restarted.join(',')
			text += `. Не перезапущены: ${line}`
		}
		vue.$notify({ type: 'success', title: text })
	},
	showTasksError (state, data) {
		const text = Array.isArray(data) ? data[0] : data
		vue.$notify({ type: 'error', title: text })
	}
}

export default mutations
