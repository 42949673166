import constants from '@/const/'

const actions = {
	getSavedData: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/serp/saved',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addSavedData', result.data)
					resolve(true)
				} else {
				}
			}, () => {
			})
		})
	},
	getConfig: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/serp/config',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addConfig', result.data)
					resolve(true)
				} else {
				}
			}, () => {
			})
		})
	},
	getProjects: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/projects',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addProjects', result.data)
					resolve(true)
				} else {
				}
			}, () => {
			})
		})
	},
	getAnalytics: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/serp/project/saved',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addAnalytics', result.data)
					resolve(true)
				} else {
				}
			}, () => {
				reject(new Error())
			})
		})
	},
	getTopOutProjects: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/top-out/project',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addTopOutProjects', result.data.items)
					resolve(true)
				}
			}, (err) => {
				reject(new Error(err.message))
			})
		})
	},
	getWsSavedSerpData: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/ws/project/saved/serp',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addWsData', result.data)
					resolve(true)
				} else {
					commit('addWsData', null)
					reject()
				}
			}, (err) => {
				reject(new Error(err.message))
			})
		})
	},
	getWsData: function ({ state, commit, getters, rootState }, input) {
		let raw
		if (input.raw) {
			raw = input.raw
			delete input.raw
		}
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/ws/project/saved',
				auth: true
			}).then(result => {
				if (result.data) {
					if (raw) {
						commit('addRawWsData', result.data)
					} else {
						commit('addWsSavedData', result.data)
					}
					resolve(true)
				}
			}, (err) => {
				reject(new Error(err.message))
			})
		})
	},
	getNotSavedQueriesCH: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input.params,
				pagination: input.pagination,
				url: 'monitoring/organic/query/not-save',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addNotSavedQueriesCh', result.data)
					resolve(true)
				}
			}, (err) => {
				reject(new Error(err.message))
			})
		})
	},

	resaveQueriesCH: function ({ state, commit, getters, rootState }, input) {
		const url = input.all ? 'extract/organic/seoapi/project/reload' : 'extract/organic/seoapi/queries/reload'

		const formData = new FormData()

		const newBody = {}
		if (input.body) {
			for (const i in input.body) {
				const item = input.body[i]
				if (Array.isArray(item)) {
					for (const k in item) {
						const key = `${i}[${k}]`
						newBody[key] = item[k]
					}
				} else if (item) {
					newBody[i] = item
				}
			}
		}

		for (const key in newBody) {
			if (newBody[key]) {
				formData.append(key, newBody[key])
			}
		}

		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.POST,
				apiName: 'hqsw',
				url,
				auth: true,
				body: formData
			}).then(() => {
				resolve(true)
			}, (err) => {
				const message = (err.body && err.body.data && err.body.data.errors && err.body.data.errors[0]) ||
				(err.body && err.body.error_messages && err.body.error_messages[0]) ||
				'Произошла ошибка при отправке данных'
				reject(new Error(message))
			})
		})
	}
}

export default actions
