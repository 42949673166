const getters = {
	i18nEntityTypesList: state => {
		if (state.i18nEntityTypesList) {
			return state.i18nEntityTypesList
		} else {
			return []
		}
	},
	i18nTranslatesList: state => {
		if (state.i18nTranslatesList) {
			return state.i18nTranslatesList
		} else {
			return []
		}
	},
	i18nPhrasesList: state => {
		if (state.i18nPhrasesList) {
			return state.i18nPhrasesList
		} else {
			return []
		}
	},
	i18nPhrasesPlaces: state => {
		if (state.i18nPhrasesPlaces) {
			return state.i18nPhrasesPlaces
		} else {
			return []
		}
	},
	i18nPhrasesTranslatesList: state => {
		if (state.i18nPhrasesTranslatesList) {
			return state.i18nPhrasesTranslatesList
		} else {
			return []
		}
	},
	wizardsGroupList: state => {
		if (state.wizardsGroupList) {
			return state.wizardsGroupList
		} else {
			return []
		}
	},
	wizardsList: state => {
		if (state.wizardsList) {
			return state.wizardsList
		} else {
			return []
		}
	}
}

export default getters
