export default {
	PROFILE: 'Произошла ошибка при получении данных пользователя',
	GET_DATA: 'Произошла ошибка при запросе данных',
	GET_LIST: 'Произошла ошибка при запросе данных',
	GET_ITEM: 'Произошла ошибка при запросе данных',
	GET_FILE: 'Произошла ошибка при получения файла',
	CREATE: 'Произошла ошибка при добавлении данных',
	UPDATE: 'Произошла ошибка при изменении данных',
	DELETE: 'Произошла ошибка при удалении данных',
	LOAD_DATA: 'Невозможно загрузить данные',
	NO_DATA_AVAILABLE: 'Данные отсутствуют',
	GET_ASIDE: 'Ошибка загрузки меню',
	NOT_VALID_DICTONARY_SOURCE: 'Не верный указан источник справочника'
}
