import AUTH from './auth.js'

/* Методы запросов */
export const METHODS = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
	DOWNLOAD: 'DOWNLOAD'
}

/* Имена записей в локальное хранилище */
const LOCAL_STORAGE_DATA = {
	LOCKER_MENU: 'sidebarLockerBlocked'
}

export const NOTIFY_STATUS = {
	SUCCESS: 'success',
	ERROR: 'error'
}

export const SEARCH_ENGINE = {
	YANDEX: 'yandex',
	GOOGLE: 'google'
}

export default {
	METHODS,
	LOCAL_STORAGE_DATA,
	NOTIFY_STATUS,
	...AUTH
}
