const mutations = {
	saveProjects (state, payload) {
		const projects = { ...state[`projects${payload.type}`] }
		const items = [...payload.items].filter(item => !projects.items.find(project => project.id === item.id))

		state[`projects${payload.type}`] = {
			hasData: true,
			items: projects.items.concat(items),
			limit: projects.limit,
			next: payload.next && !!payload.items.length,
			search: projects.search
		}
	}
}

export default mutations
