import Vue from 'vue'
import container from './container/'
import { router } from '../router/index.js'
import store from '../store/'
import vuetify from '../plugins/vuetify'
import notify from '../components/notify/'
import './importComponents'
import './importLibs'
/* Функция запроса */
import { init } from '../function/request.js'

Vue.config.productionTip = false

Vue.use(notify)

Vue.http.get('/config.json').then(response => {
	/* Конфигурация приложения */
	const config = { ...response.data }

	/* Запись конфигурации в хранилище */
	store.state.config = config

	/* Инициализация функции запроса */
	store.state.request = init(config).request

	new Vue({
		store,
		vuetify,
		router: router(config),
		render: h => h(container)
	}).$mount('#app')
}, () => {
	alert('Not found config')
})
