import Vue from 'vue'

import VueCookie from 'vue-cookie'

/* Запросы */
import VueResource from 'vue-resource'

/* Библиотека для работы с токеном аутентификации */
import jwtDecode from 'jwt-decode'

/* Константы */
import constants from '@/const'

Vue.use(VueCookie)
Vue.use(VueResource)

/* Библиотека для работы с cookies */
const Cookies = Vue.cookie

/* ID виджета intercom */
const INTERCOM_ID = 'igbsgum2'

const getMainDomain = function () {
	const brokenDomain = document.location.hostname.split('.')
	const countFragments = brokenDomain.length

	return countFragments === 1
		? brokenDomain[0]
		: brokenDomain[countFragments - 2] + '.' + brokenDomain[countFragments - 1]
}

/*
 * Получение токена
 */
export function getTokenData () {
	try {
		return jwtDecode(Cookies.get(constants.AUTH.ACCESS_TOKEN))
	} catch (_) {
		return undefined
	}
}

/*
 * Получение данных пользователя
 */
export function getUser () {
	const token = getTokenData()
	const current = token && token.current ? token.current : {}

	function roleСheck (roleId) {
		try {
			return current.role.id === roleId
		} catch (_) {
			return false
		}
	}

	return {
		...current,

		/* Рот пользователь */
		isRoot: () => roleСheck(constants.ROLES.ROOT),

		/* Админ */
		isAdministrator: () => roleСheck(constants.ROLES.ADMINISTRATOR)
	}
}

/*
 * Проверка существования сессии
 */
export function isLoggedIn () {
	return !!(Cookies && Cookies.get(constants.AUTH.ACCESS_TOKEN) && Cookies.get(constants.AUTH.REFRESH_TOKEN))
}

/*
 * Функция создания сессии
 * config - Конфигурация приложения
 * tokens - Объект токенов авторизации
 * hash - Парсинг токенов из хеш-строки
 */
export function logIn (config, tokens, hash = false) {
	try {
		if (hash) {
			tokens = tokens.split('&&')
			tokens = {
				access_token: tokens[0],
				refresh_token: tokens[1]
			}
		}

		const tokenData = jwtDecode(tokens.access_token)

		if (tokenData && tokenData.exp) {
			// Дата истечения токенов
			const expTime = new Date(tokenData.exp * 1000)
			const rxpTime = tokenData.rxp ? new Date(tokenData.rxp * 1000) : expTime

			// Сохранение данных
			Cookies.set(constants.AUTH.ACCESS_TOKEN, tokens.access_token, {
				expires: expTime,
				domain: getMainDomain()
			})

			Cookies.set(constants.AUTH.REFRESH_TOKEN, tokens.refresh_token, {
				expires: rxpTime,
				domain: getMainDomain()
			})

			/* Постановка задачи на рефреш токенов */
			if (config) {
				refresh(config)
			}

			return isLoggedIn()
		} else {
			throw Error()
		}
	} catch (_) {
		return false
	}
}

/*
 * Функция удаление сессии
 */
export async function logOut (config) {
	const accessToken = Cookies.get(constants.AUTH.ACCESS_TOKEN)

	localStorage.removeItem(constants.LOCAL_STORAGE_DATA.ACTIVE_PROJECT)

	Cookies.delete(constants.AUTH.REFRESH_TOKEN, {
		domain: getMainDomain()
	})

	if (accessToken) {
		Cookies.delete(constants.AUTH.ACCESS_TOKEN, {
			domain: getMainDomain()
		})

		await Vue.http.delete(config.api.default + '/auth', {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		})
			.then()
			.catch()
	}

	return !isLoggedIn()
}

/*
 * Запрос пары токенов
 */
export function tokensRequest (config, refresh) {
	return new Promise((resolve, reject) => {
		const body = {}

		if (refresh) {
			body[constants.AUTH.REFRESH_TOKEN] = Cookies.get(constants.AUTH.REFRESH_TOKEN)
		}

		if (!config && window.vue.$store.state.config) {
			config = {
				api: window.vue.$store.state.config.api.default
			}
		}

		/* Время жизни токенов (в секундах) */
		// body.ttl = 15

		/* Отправка запроса на рефреш токена */
		Vue.http.post(config.api.default + '/auth/refresh', body).then(response => {
			const tokens = response.data.data

			if (tokens.access_token && tokens.refresh_token) {
				resolve(tokens)
			} else {
				reject(Error())
			}
		}, () => {
			reject(Error())
		})
	})
}

/*
 * Функция обновления пары токенов
 * (запускается при авторизации или запуске приложения)
 */
export function refresh (config) {
	const token = getTokenData()

	const tokensRequestRefresh = () => {
		tokensRequest(config, true).then(tokens => {
			logIn(config, tokens)
		}, () => {
			if (logOut()) {
				// document.location.href = '/login';
			}
		})
	}

	if (token) {
		/* Время истечения сессии (в мс) */
		const expTime = token.exp * 1000

		/* Текущее время */
		const curTime = Number(new Date().getTime())

		/* На сколько раньше до протухания токена запрашивать новую пару */
		const inAdvanceTime = 60000

		if (expTime && curTime && expTime >= (curTime - inAdvanceTime)) {
			if (window.refreshTask) {
				clearTimeout(window.refreshTask)
			}

			/* Постановка задачи на обновление токенов */
			window.refreshTask = setTimeout(() => {
				tokensRequestRefresh()
			}, expTime - curTime - inAdvanceTime)
		} else {
			/*
             * В случае если до протухания токена осталось
             * меньше ${inAdvanceTime} секунд, то запрашиваем новую пару.
             */
			tokensRequestRefresh()
		}
	} else if (Cookies.get(constants.AUTH.REFRESH_TOKEN)) {
		/*
         * Если токен уже протух, но refresh еще существует
         * пытаемся запросить новую пару
         */
		tokensRequestRefresh()
	}
}

/*
 * Стартовая проверка токенов и загрузка рефреша
 */
export function startLogin (config) {
	return new Promise((resolve, reject) => {
		try {
			const accessToken = getTokenData()
			const refreshToken = Cookies.get(constants.AUTH.REFRESH_TOKEN)

			if (accessToken) {
				refresh(config)
				resolve(true)
			} else if (!accessToken && refreshToken) {
				tokensRequest(config, true).then(tokens => {
					if (logIn(config, tokens)) {
						resolve(true)
					} else {
						throw Error()
					}
				}, () => {
					reject(Error())
				})
			} else {
				reject(Error())
			}
		} catch (_) {
			reject(Error())
		}
	})
}

/*
 * Библиотека для работы с данными
 */
export { Cookies, INTERCOM_ID }
