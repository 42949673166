import Vue from 'vue'

const mutations = {
	addSavedData (state, data) {
		Vue.set(state, 'savedData', data)
	},
	addConfig (state, data) {
		Vue.set(state, 'config', data)
	}
}

export default mutations
