import AUTH from '@/const/auth.js'
import { Cookies } from '@/function/auth.js'

const accessToken = Cookies.get(AUTH.ACCESS_TOKEN)

const actions = {
	getSessions: async function ({ state, commit, getters, rootState }, input) {
		try {
			const response = await fetch(`https://seoapi.hqsw.ru/api/session/list/organic/${input}`, { Authorizarion: 'Bearer ' + accessToken })
			const sessions = await response.json()
			commit('setSessions', sessions)
		} catch (e) {
			throw new Error(`${e}`)
		}
	},
	getSessionsWS: async function ({ state, commit, getters, rootState }) {
		try {
			const response = await fetch('https://seoapi.hqsw.ru/api/session/list/ws/yandex', { Authorizarion: 'Bearer ' + accessToken })
			const sessions = await response.json()
			commit('setSessionsWS', sessions)
		} catch (e) {
			throw new Error(`${e}`)
		}
	}
}

export default actions
