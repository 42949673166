/* Методы запросов */
const AUTH = {
	ACCESS_TOKEN: 'access_token',
	REFRESH_TOKEN: 'refresh_token'
}

/* Роли пользователей */
const ROLES = {
	ROOT: 1,
	ADMIN: 2,
	OPTIMIZATOR: 3,
	LINKER: 4,
	MANAGER: 5,
	SALE: 6,
	ANALITIC: 7,
	CRON: 8,
	AREA_OWNER: 9,
	AREA: 10,
	MIGRATE: 11,
	WEB_DEV: 12,
	HR: 13,
	LOCALHOST: 14,
	SPECIALIST: 15,
	ADMINISTRATOR: 16,
	FINANCE: 17,
	ANALITIC_SEOWORK: 18,
	CLIENT: 19,
	MARKETING: 20
}

export default {
	AUTH,
	ROLES
}
