import constants from '@/const/'
import errors from '@/store/errors'

const actions = {
	getTasks: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'gsc/tasks',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addTasks', result.data)
				}
				resolve(true)
			}, () => {
			})
		})
	},
	getStats: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'gsc/tasks/statistic',
				auth: true
			}).then(result => {
				if (result.data && result.data.base_statistic) {
					commit('addStats', result.data.base_statistic)
				}
				resolve(true)
			}, () => {
			})
		})
	},
	getProjects: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'gsc/filter/projects',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addProjects', result.data)
				}
				resolve(true)
			}, () => {
			})
		})
	},
	getUrls: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				url: `gsc/filter/urls/${input}`,
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addUrls', result.data)
				}
				resolve(true)
			}, () => {
			})
		})
	},
	restartTasks: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.PUT,
				apiName: 'hqsw',
				body: {
					tasks: input
				},
				url: 'gsc/tasks',
				auth: true
			}).then(result => {
				if (result.data && result.status === 200) {
					commit('showRestartedTasks', result.data)
				} else {
					commit('showTasksError', errors.UPDATE)
				}
				resolve(true)
			}, () => {
				commit('showTasksError', errors.UPDATE)
			})
		})
	}
}

export default actions
