import constants from '@/const/'
import errors from '../../errors.js'
import Vue from 'vue'
const vue = Vue.prototype
const actions = {
	getTranslateEntityTypesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'v1/i18n/entity-types',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('setI18nEntityTypesList', result.data)
					resolve(true)
				} else {
					// reject(errors.GET_ASIDE)
				}
			}, () => {
				// reject(errors.GET_ASIDE)
			})
		})
	},
	postTranslateEntityTypesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.POST,
				apiName: 'hqsw',
				url: 'v1/i18n/entity-types',
				auth: true,
				body: input
			}).then(result => {
				if (result.data) {
					resolve(true)
				} else {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(result.data.source.join('\n'))
				}
			}, () => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Не удалось создать тип справочника')
			})
		})
	},

	putTranslateEntityTypesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.PUT,
				apiName: 'hqsw',
				url: 'v1/i18n/entity-types/' + input.id,
				auth: true,
				body: input
			}).then(result => {
				if (result.data) {
					resolve(true)
				} else {
					reject(result.data.source.join('\n'))
				}
			}, () => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Не удалось обновить тип справочника')
			})
		})
	},

	deleteTranslateEntityTypesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.DELETE,
				apiName: 'hqsw',
				url: 'v1/i18n/entity-types/' + input.id,
				auth: true,
				body: input
			}).then(result => {
				commit('deleteEntityType', input.id)
				resolve(true)
			}, () => {
				reject(errors.DELETE)
			})
		})
	},

	getTranslatesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'v1/i18n/translates',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('setI18nTranslatesList', result.data)
					resolve(true)
				} else {
					reject(errors.GET_ASIDE)
				}
			}, () => {
				reject(errors.GET_ASIDE)
			})
		})
	},
	getWizardsGroupList: async function ({ state, commit, getters, rootState }, input) {
		try {
			const res = await rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: { limit: '150' },
				url: 'semantics/wizards/group',
				auth: true
			})
			if (res.status >= 200 && res.status < 300) {
				commit('setWizardsGroupList', res.data)
				return Promise.resolve(true)
			}
			throw new Error('Не удалось получить список групп колдунщиков')
		} catch (e) {
			vue.$notify({ type: 'error', title: e.message })
			return Promise.reject(e.message)
		}
	},
	getWizardsList: async function ({ state, commit, getters, rootState }, input) {
		try {
			const res = await rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: { limit: '150' },
				url: 'semantics/wizards/catalog',
				auth: true
			})
			if (res.status >= 200 && res.status < 300) {
				commit('setWizardsList', res.data)
				return Promise.resolve(true)
			}
			throw new Error('Не удалось получить список колдунщиков')
		} catch (e) {
			vue.$notify({ type: 'error', title: e.message })
			return Promise.reject(e.message)
		}
	},
	postTranslatesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.POST,
				apiName: 'hqsw',
				url: 'v1/i18n/translates',
				auth: true,
				body: input
			}).then(result => {
				if (result.data) {
					resolve(true)
				} else {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(result.data.source.join('\n'))
				}
			}, () => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Не удалось создать тип справочника')
			})
		})
	},
	postWizardGroup: async function ({ state, commit, getters, rootState }, input) {
		try {
			const res = await rootState.request({
				type: constants.METHODS.POST,
				apiName: 'hqsw',
				url: 'semantics/wizards/group',
				auth: true,
				body: input
			})
			if (res.status >= 200 && res.status < 300) {
				vue.$notify({ type: 'info', title: 'Группа колдунщиков создана' })
				return Promise.resolve(true)
			}
			throw new Error('Не удалось создать группу колдунщиков')
		} catch (e) {
			vue.$notify({ type: 'error', title: e.message })
			return Promise.reject(e.message)
		}
	},
	postWizardItem: async function ({ state, commit, getters, rootState }, input) {
		try {
			const res = await rootState.request({
				type: constants.METHODS.POST,
				apiName: 'hqsw',
				url: 'semantics/wizards/catalog',
				auth: true,
				body: input
			})
			if (res.status >= 200 && res.status < 300) {
				vue.$notify({ type: 'info', title: 'Колдунщик создан' })
				return Promise.resolve(true)
			}
			throw new Error('Не удалось создать колдунщик')
		} catch (e) {
			vue.$notify({ type: 'error', title: e.message })
			return Promise.reject(e.message)
		}
	},
	deleteWizardItem: async function ({ state, commit, getters, rootState }, input) {
		try {
			const res = await rootState.request({
				type: constants.METHODS.DELETE,
				apiName: 'hqsw',
				url: 'semantics/wizards/catalog/' + input.id,
				auth: true
			})
			if (res.status >= 200 && res.status < 300) {
				vue.$notify({ type: 'info', title: 'Колдунщик удален' })
				return Promise.resolve(true)
			}
			throw new Error('Не удалось удалить колдунщик')
		} catch (e) {
			vue.$notify({ type: 'error', title: e.message })
			return Promise.reject(e.message)
		}
	},
	putTranslatesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.PUT,
				apiName: 'hqsw',
				url: 'v1/i18n/translates/' + input.id,
				auth: true,
				body: input
			}).then(result => {
				if (result.data) {
					resolve(true)
				} else {
					reject(result.data.source.join('\n'))
				}
			}, () => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Не удалось обновить тип справочника')
			})
		})
	},
	putWizardGroup: async function ({ state, commit, getters, rootState, dispatch }, { input, isRefresh }) {
		try {
			const res = await rootState.request({
				type: constants.METHODS.PUT,
				apiName: 'hqsw',
				url: 'semantics/wizards/group/' + input.id,
				auth: true,
				body: input
			})
			if (res.status >= 200 && res.status < 300) {
				vue.$notify({ type: 'info', title: 'Группа колдунщиков обновлена' })
				if (isRefresh) {
					dispatch('getWizardsList')
					dispatch('getWizardsGroupList')
				}
				return Promise.resolve(true)
			}
			throw new Error('Не удалось обновить группу колдунщиков')
		} catch (e) {
			vue.$notify({ type: 'error', title: e.message })
			return Promise.reject(e.message)
		}
	},
	deleteWizardGroup: async function ({ state, commit, getters, rootState }, input) {
		try {
			const res = await rootState.request({
				type: constants.METHODS.DELETE,
				apiName: 'hqsw',
				url: 'semantics/wizards/group/' + input.id,
				auth: true
			})
			if (res.status >= 200 && res.status < 300) {
				vue.$notify({ type: 'info', title: 'Группа колдунщиков удалена' })
				return Promise.resolve(true)
			}
			throw new Error('Не удалось удалить группу колдунщиков')
		} catch (e) {
			vue.$notify({ type: 'error', title: e })
			return Promise.reject(e.message)
		}
	},
	putWizardItem: async function ({ state, commit, getters, rootState, dispatch }, input) {
		try {
			const res = await rootState.request({
				type: constants.METHODS.PUT,
				apiName: 'hqsw',
				url: 'semantics/wizards/catalog/' + input.id,
				auth: true,
				body: input
			})
			if (res.status >= 200 && res.status < 300) {
				vue.$notify({ type: 'info', title: 'Колдунщик обновлен' })
				dispatch('getWizardsList')
				dispatch('getWizardsGroupList')
				return Promise.resolve(true)
			}
			throw new Error('Не удалось обновить колдунщик')
		} catch (e) {
			vue.$notify({ type: 'error', title: e.message })
			return Promise.reject(e.message)
		}
	},
	deleteTranslatesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.DELETE,
				apiName: 'hqsw',
				url: 'v1/i18n/translates/' + input.id,
				auth: true,
				body: input
			}).then(result => {
				commit('deleteTranslate', input.id)
				resolve(true)
			}, () => {
				reject(errors.DELETE)
			})
		})
	},
	getPhrasesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'v1/i18n/phrases',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('setI18nPhrasesList', result.data)
					resolve(true)
				} else {
					// reject(errors.GET_ASIDE)
				}
			}, () => {
				// reject(errors.GET_ASIDE)
			})
		})
	},
	postPhrasesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.POST,
				apiName: 'hqsw',
				url: 'v1/i18n/phrases',
				auth: true,
				body: input
			}).then(result => {
				if (result.data) {
					resolve(true)
				} else {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(result.data.source.join('\n'))
				}
			}, () => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Не удалось создать тип справочника')
			})
		})
	},

	putPhrasesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.PUT,
				apiName: 'hqsw',
				url: 'v1/i18n/phrases/' + input.id,
				auth: true,
				body: input
			}).then(result => {
				if (result.data) {
					resolve(true)
				} else {
					reject(result.data.source.join('\n'))
				}
			}, () => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Не удалось обновить тип справочника')
			})
		})
	},

	deletePhrasesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.DELETE,
				apiName: 'hqsw',
				url: 'v1/i18n/phrases/' + input.id,
				auth: true,
				body: input
			}).then(result => {
				commit('deleteTranslate', input.id)
				resolve(true)
			}, () => {
				reject(errors.DELETE)
			})
		})
	},

	getPhrasesTranslatesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'v1/i18n/phrases-translates',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('setI18nPhrasesTranslatesList', result.data)
					resolve(true)
				} else {
					// reject(errors.GET_ASIDE)
				}
			}, () => {
				// reject(errors.GET_ASIDE)
			})
		})
	},
	postPhrasesTranslatesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.POST,
				apiName: 'hqsw',
				url: 'v1/i18n/phrases-translates',
				auth: true,
				body: input
			}).then(result => {
				if (result.data) {
					resolve(true)
				} else {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(result.data.source.join('\n'))
				}
			}, () => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Не удалось создать тип справочника')
			})
		})
	},

	putPhrasesTranslatesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.PUT,
				apiName: 'hqsw',
				url: 'v1/i18n/phrases-translates/' + input.id,
				auth: true,
				body: input
			}).then(result => {
				if (result.data) {
					resolve(true)
				} else {
					reject(result.data.source.join('\n'))
				}
			}, () => {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Не удалось обновить тип справочника')
			})
		})
	},

	deletePhrasesTranslatesList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.DELETE,
				apiName: 'hqsw',
				url: 'v1/i18n/phrases-translates/' + input.id,
				auth: true,
				body: input
			}).then(result => {
				commit('deleteTranslate', input.id)
				resolve(true)
			}, () => {
				reject(errors.DELETE)
			})
		})
	},
	getPlaces: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'v1/i18n/places',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('setI18nPhrasesPlaces', result.data)
					resolve(true)
				} else {
					// reject(errors.GET_ASIDE)
				}
			}, () => {
				// reject(errors.GET_ASIDE)
			})
		})
	}
}

export default actions
