<template>
    <!-- Список -->
    <li v-if="typeList" :class="['aside-item', '_list', { '_active': isActiveList } ]">
        <popper
            v-if="menuNarrow"
            trigger="hover"
            :visible-arrow="false"
            placement="right-start"
            :options="{
                placement: 'right-start',
                positionFixed: true,
                modifiers: {
                    preventOverflow: {
                        boundariesElement: 'viewport'
                    }
                }
            }"
        >
            <ul class="aside-item__internal popper">
                <span class="aside-item__internal-title">{{ data.title }}</span>
                <help
                    v-for="(item, index) in data.list"
                    :data="item"
                    :key="index"
                    :index="index"
                    :level="level + 1"
                    :menuNarrow="menuNarrow"
                />
            </ul>

            <span slot="reference" class="aside-item__title" @click="listToggle">
                <icon v-if="data.icon" class="aside-item__icon" :name="data.icon"></icon>
                <icon v-else :class="[ 'aside-item__arrow', { '_rotate': isActiveList } ]" name="arrow_right"></icon>
                <span class="aside-item__name">{{ data.title }}</span>
                <component :is="componentTag" :data="data"/>
            </span>
        </popper>

        <template v-else>
            <span class="aside-item__title" @click="listToggle">
                <icon v-if="data.icon" class="aside-item__icon" :name="data.icon"></icon>
                <icon v-else :class="[ 'aside-item__arrow', { '_rotate': isActiveList } ]" name="arrow_right"></icon>
                <span class="aside-item__name">{{ data.title }}</span>
                <component :is="componentTag" :data="data"/>
            </span>

            <transition
                @before-enter="beforeEnter"
                @after-enter="afterEnter"
                @before-leave="beforeLeave"
                @after-leave="afterLeave"
            >
                <ul v-show="isActiveList" ref="list" class="aside-item__internal">
                    <span class="aside-item__internal-title">{{ data.title }}</span>
                    <help
                        v-for="(item, index) in data.list"
                        :data="item"
                        :key="index"
                        :index="index"
                        :level="level + 1"
                    />
                </ul>
            </transition>
        </template>
    </li>

    <!-- Евент кнопка -->
    <li v-else-if="typeEvent" class="aside-item _link">
        <button class="aside-item__link" @click="data.actionClick">
            <icon v-if="data.icon" class="aside-item__icon" :name="data.icon"></icon>
            <span class="aside-item__name">{{ data.title }}</span>
            <component :is="componentTag" :data="data"/>
        </button>
    </li>
</template>

<script>
/* Компонент тега */
import Popper from 'vue-popperjs'

const componentTagRender = function (data) {
	return {
		template: `
                <span v-if="data.tagtext" class="aside__tagtext" :style="textStyle">
                    {{ data.tagtext.text }}
                </span>

                <span v-else-if="data.tagicon" class="aside__icontag" v-tippy="typpyStyle" :content="iconText" ref="icontag">
                    <icon :name="data.tagicon.icon"></icon>
                </span>
            `,

		props: {
			data: {
				type: Object
			}
		},

		data () {
			return {}
		},

		computed: {
			typpyStyle () {
				return {
					popperOptions: {
						modifiers: {
							preventOverflow: {
								enabled: false
							},
							hide: {
								enabled: false
							}
						}
					}
				}
			},

			iconText () {
				try {
					return this.data.tagicon.text
				} catch (_) {
					return false
				}
			},

			textStyle () {
				if (this.data.tagtext) {
					return `background-color: ${this.data.tagtext.color}`
				}
			}
		},

		watch: {
			iconText (val) {
				this.updateView()
			}
		},

		mounted () {
			this.updateView()
		},

		methods: {
			updateView () {
				this.$nextTick(() => {
					if (this.$refs.icontag) {
						if (this.iconText) {
							this.$refs.icontag._tippy.enable()
						} else {
							this.$refs.icontag._tippy.disable()
						}
					}
				})
			}
		}
	}
}

export default {
	name: 'help',

	components: {
		popper: Popper
	},

	data () {
		return {
			/* Переключатель анимации */
			animation: false,

			/* Флаг активности */
			isActive: true
		}
	},

	props: {
		/* Скисок ссылок и разделов */
		data: {
			type: Object,
			required: true,
			default: function () {
				return {}
			}
		},

		/* Уровень погружения */
		level: {
			type: Number,
			default: 1
		},

		/* Индекс элемента */
		index: {
			type: Number
		},

		menuNarrow: {
			type: Boolean,
			default: false
		},

		/* Стартовое отображение раздела */
		startShow: {
			type: Boolean,
			default: true
		}
	},

	computed: {
		/* Компонент ссылки */
		componentTag () {
			return componentTagRender(this.data)
		},

		/* Тип: список */
		typeList () {
			return this.data.type === 'list'
		},

		/* Тип: роут */
		typeEvent () {
			return this.data.type === 'event' && this.data.actionClick
		},

		/* Флаг активности списка */
		isActiveList () {
			return !!(this.typeList && this.isActive)
		}
	},

	mounted () {
		/* Загрузка начального положения */
		this.$set(this, 'isActive', this.startShow)
	},

	methods: {
		/* Управление раскрытем списка */
		listToggle () {
			this.isActive ^= true
			this.$emit('isActiveUpdate', this.isActive)

			if (!this.animation) { this.animation = true }
		},

		/* Появление списка */
		beforeEnter (el) {
			if (this.animation) {
				// Setup clone
				const clone = el.cloneNode(true); let h
				clone.style.width = el.style.width
				clone.style.visibility = 'hidden'
				clone.style.removeProperty('display')

				// Анимация внутренних списков (если таковые существуют)
				const desc = clone.querySelectorAll('*');
				[].forEach.call(desc, function (item) {
					item.style.removeProperty('none')
				})

				el.parentNode.appendChild(clone)
				clone.remove()

				// Принудительная анимация
				el.style.height = '0px'
				setTimeout(() => {
					el.style.height = h + 'px'
				}, 1)
			}
		},

		afterEnter (el) {
			if (this.animation) {
				el.style.removeProperty('height')
			}
		},

		/* Скрытие списка */
		beforeLeave (el) {
			el.style.height = el.clientHeight + 'px'
			setTimeout(() => {
				el.style.height = '0px'
			}, 1)
		},

		afterLeave (el) {
			el.style.removeProperty('height')
		}
	}
}
</script>

<style lang="scss" src="./aside.scss" scoped></style>
