import Vue from 'vue'
import Url from 'url-parse'

import constants from '@/const/'
import { isLoggedIn, Cookies } from './auth.js'

import VueResource from 'vue-resource'
Vue.use(VueResource)

const ERROR_CONFIG = 'Ошибка конфигурации приложения'

/*
 * Функция инициализации запросов
 */
export function init (config) {
	return {
		request: (input) => {
			return request({
				config,
				...input
			})
		}
	}
}

/*
 * Функция отправки запроса
 */
export function request (input) {
	return new Promise((resolve, reject) => {
		validate(input).then(valide => {
			/*
             * Добавление авторизационных данных в запрос
             */
			const accessToken = Cookies.get(constants.AUTH.ACCESS_TOKEN)

			let headers = {
				'X-Referrer': location.href,
				'X-Origin': location.origin
			}

			if (input.headers) {
				headers = {
					...headers,
					...input.headers
				}
			}

			if (input.auth && accessToken) {
				headers.Authorization = 'Bearer ' + accessToken
			}

			const credentials = !!input.credentials

			/*
             * Генераци адреса запроса
             */
			let url

			try {
				switch (true) {
				case !!input.straightUrl:
					url = Url(input.straightUrl)
					break

				case !!input.mock:
					url = Url(input.config.api.mock)
					url.set('pathname', url.pathname + input.url)
					break

				case !!input.apiName:
					url = Url(input.config.api[input.apiName])
					url.set('pathname', url.pathname + input.url)
					break

				default:
					url = Url(input.config.api.default)
					url.set('pathname', url.pathname + input.url)
				}
			} catch (_) {
				reject(ERROR_CONFIG)
			}

			/*
             * Добавление query параметров
             */
			const newQueries = {}
			if (input.query) {
				for (const i in input.query) {
					const item = input.query[i]
					if (Array.isArray(item)) {
						for (const k in item) {
							const key = `${i}[${k}]`
							newQueries[key] = item[k]
						}
					} else if (item) {
						newQueries[i] = item
					}
				}
			}

			/*
             * Добавление пагинации
             */
			if (input.pagination) {
				const p = input.pagination
				if (p.limit) {
					newQueries.limit = p.limit
				}

				if (p.page && p.page > 1) {
					newQueries.offset = (p.page - 1) * p.limit
				} else if (p.offset) {
					newQueries.offset = p.offset
				}
			}

			if (url) {
				url.set('query', newQueries)
			}

			/*
             * Создание тела запроса
             */
			switch (input.type) {
			case constants.METHODS.POST:
			case constants.METHODS.PUT:
				/*
                     * Проверка тела запроса
                     */
				if (!input.body) {
					reject(Error('Укажите тело запроса'))
				}

				Vue.http[input.type.toLowerCase()](
					url.href,
					input.body,
					{
						headers,
						credentials
					}
				).then(response => {
					// updateExpTime();
					resolve(response.body)
				}, error => {
					reject(error)
				})
				break

			default:
				Vue.http[input.type.toLowerCase() || 'get'](url.href, { headers, credentials }).then(response => {
					// updateExpTime();
					resolve(input.response ? response : response.body)
				}, error => {
					reject(error)
				})
			}
		}, error => {
			reject(error)
		})
	})
}

/*
 * Валидация запроса
 */
export function validate (input) {
	return new Promise((resolve, reject) => {
		/*
         * Проверка адреса запроса
         */
		if (!input.config && (input.config.api[input.apiName] || input.config.api.default)) {
			reject(Error('Укажите конфигурационные данные'))
		}

		/*
         * Проверка адреса запроса
         */
		if (!input.url && !input.straightUrl) {
			reject(Error('Укажите адрес запроса'))
		}

		/*
         * Проверка типа запроса
         */
		if (!input.type) {
			reject(Error('Укажите тип запроса'))
		}

		/*
         * Проверка на существование сессии и
         * валидности токена
         */
		if (input.auth && !isLoggedIn()) {
			document.location.href = '/login?to=' + window.location.pathname + window.location.search
		} else {
			resolve(true)
		}
	})
}
