const state = {
	/* Конфигурация приложения */
	config: {},

	/* Профиль пользователя */
	profile: {},

	/* Объект меню */
	aside: {},

	options: {
		/* Список проектов */
		projects: {
			limit: 100,
			offset: 0,
			next: true,
			list: [],
			total: 0,

			search: {
				limit: 100,
				offset: 0,
				next: true,
				items: []
			}
		}
	}
}

export default state
