const getters = {
	items: (state) => (tab) => {
		if (state.savedData && state.savedData[tab]) {
			return state.savedData[tab]
		}
		return []
	},
	wsDataByTab: (state) => (searchEngine) => {
		if (state.wsData && state.wsData[searchEngine]) {
			return state.wsData[searchEngine].map((el) => {
				const newObj = {}
				for (const key of Object.keys(el)) {
					const value = el[key]
					const newKey = key !== 'project_id' ? `ws_serp_${key}` : key
					newObj[newKey] = value
				}
				return newObj
			})
		}
		return []
	},
	columns: (state) => {
		if (state.config && state.config.columns) {
			return Array.from(Object.values(state.config.columns)).map((el) => {
				const col = {
					...el,
					value: el.name,
					text: el.title,
					sortable: false
				}
				delete col.title
				delete col.name
				return col
			}).filter((el) => el.sort !== undefined).sort((a, b) => (a.sort > b.sort) ? 1 : -1)
		}
		return []
	},
	tabs: (state) => {
		if (state.config && state.config.tabs) {
			return Array.from(Object.values(state.config.tabs))
		}
		return []
	},
	filteredTabs: (state, getters) => {
		const forbiddenTabs = ['unknown', 'unknown-yandex', 'unknown-google']
		const hasData = (el) => {
			const items = getters.items(el)
			return items.length
		}
		const tabs = getters.tabs
		return tabs.filter((el) => hasData(el.name) && !forbiddenTabs.includes(el.name)).sort((a, b) => (a.sort > b.sort) ? 1 : -1).map((el, idx) => {
			return {
				...el,
				id: idx
			}
		})
	},
	projects: (state) => {
		if (state.projects && state.projects.items.length) {
			return state.projects.items.map((el, idx) => {
				return {
					...el,
					idx: idx
				}
			})
		}
		return []
	},
	analytics: (state) => (tab) => {
		if (state.analytics && state.analytics.items[tab]) {
			return state.analytics.items[tab]
		}
		return []
	},
	notSavedQueriesCh: (state) => {
		if (state.notSavedQueriesCh && state.notSavedQueriesCh.length) {
			return state.notSavedQueriesCh
		}
		return []
	}
}

export default getters
