import Vue from 'vue'

const mutations = {
	setFeatures (state, payload) {
		state.features = payload.items
		state.total = payload.total
	},
	updateFeature (state, payload) {
		const featureToUpdateIdx = state.features.findIndex(feature => feature.id === payload.id)
		if (featureToUpdateIdx !== -1) {
			Vue.set(state.features, featureToUpdateIdx, {
				...state.features[featureToUpdateIdx],
				...payload.body
			})
		}
	}
}

export default mutations
