import Vue from 'vue'

const mutations = {
	addSavedData (state, data) {
		Vue.set(state, 'savedData', data)
	},
	addConfig (state, data) {
		Vue.set(state, 'config', data)
	},
	addProjects (state, data) {
		Vue.set(state, 'projects', data)
	},
	clearProjects (state, data) {
		Vue.set(state, 'projects', null)
	},
	addAnalytics (state, data) {
		Vue.set(state, 'analytics', data)
	},
	addTopOutProjects (state, data) {
		Vue.set(state, 'topOutProjects', data)
	},
	clearTopOutProjects (state) {
		Vue.set(state, 'topOutProjects', [])
	},
	addWsData (state, data) {
		Vue.set(state, 'wsData', data)
	},
	addWsSavedData (state, data) {
		const items = []
		if (Object.values(data).length) {
			Object.values(data).map((value) => {
				items.push(value)
			})
		}
		Vue.set(state, 'wsSavedData', items.flat())
	},
	addRawWsData (state, data) {
		Vue.set(state, 'wsSavedData', data)
	},
	clearWsData (state) {
		Vue.set(state, 'WsData', null)
	},
	addNotSavedQueriesCh (state, data) {
		Vue.set(state, 'notSavedQueriesCh', data)
	}
}

export default mutations
