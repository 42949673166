<template>
    <div :class="['aside-settings', { '_active': active }]">
        <div class="aside-settings__container">
            <div class="aside-settings__return" @click="toggle">
                <icon class="aside-settings__return-icon" name="chevron_left"></icon>
            </div>

            <div class="aside-settings__body">
                <template v-for="(list, i) in renderList">
                    <div :key="`aside-settings__title-${i}`" class="aside-settings__title">{{ list.title }}</div>
                    <ul :key="`aside-settings__nav-${i}`" class="aside-settings__nav">
                        <li
                            v-for="(item, i) in list.items"
                            :key="`aside-settings-link-${i}`"
                        >
                            <span class="aside-settings__link-title">
                                <icon class="aside-settings__link-icon" :name="item.icon"></icon>
                                <span>{{ item.title }}</span>
                            </span>

                            <ul class="aside-settings__links">
                                <template v-for="(link, i) in item.items">
                                    <li :key="i" v-if="link && (link.view || link.type === 'intercom')">
                                        <router-link
                                            v-if="link.type === 'route' && link.spa_route"
                                            class="aside-settings__link"
                                            :to="link.spa_route"
                                        >
                                            {{ link.title }}
                                        </router-link>

                                        <button
                                            v-else-if="link.type === 'event' && link.event"
                                            class="aside-settings__link"
                                            @click="link.event"
                                        >
                                            {{ link.title }}
                                        </button>

                                        <button
                                            v-else-if="link.type === 'intercom'"
                                            class="aside-settings__link"
                                            @click="interComstartTour(link)"
                                        >
                                            {{ link.title }}
                                        </button>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </ul>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		data: {
			type: [Array, Object],
			default: () => {
				return []
			}
		},

		active: {
			type: [Number, Boolean],
			default: false
		},

		toggle: {
			type: Function
		}
	},

	computed: {
		/* Конфигурация */
		config () {
			return this.$store.state.config
		},

		renderList () {
			return this.data.filter(element => element.view)
		}
	},

	methods: {
		/* Открытие модального окна Intercom */
		interComstartTour (data) {
			this.$notify({ type: 'error', title: 'Ошибка запуска виджета' })
			// if (Intercom && data.params) {
			// Intercom('startTour', data.params.id)
			// } else {
			// this.$notify({ type: 'error', title: 'Ошибка запуска виджета' })
			// }
		}
	}
}
</script>

<style lang="scss" src="./aside.scss" scoped></style>
