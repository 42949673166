import Vue from 'vue'

import preloader from '../components/preloader/'

import aside from '../components/aside/'

import header from '../components/header/'

import icon from '../components/icon/'

Vue.component('v-preloader', preloader)
Vue.component('v-aside', aside)
Vue.component('v-header', header)
Vue.component('icon', icon)
