import { METHODS } from '@/const'
import ERRORS from '@/store/errors'

const actions = {
	getProjects ({ state, commit, rootState }, input) {
		return new Promise((resolve, reject) => {
			const projects = { ...state[`projects${input.type}`] }
			const query = {
				limit: projects.limit,
				q: input && input.q,
				project_id: input && input.project_id,
				project_type: input && input.type === 'DF' ? 'dataforce.io' : 'seowork.ru'
			}

			rootState.request({
				type: METHODS.GET,
				url: '/projects',
				auth: true,
				apiName: 'seowork',
				query: {
					active: 1,
					...query
				}
			})
				.then(result => {
					if (result && result.has_data && result.data && result.data.items) {
						commit('saveProjects', {
							hasData: true,
							items: result.data.items || [],
							next: result.data.has_more,
							type: input && input.type
						})
					}
					resolve({ next: result && result.data && result.data.has_more && result.data.items && !!result.data.items.length })
				}, (_) => {
					reject(new Error(ERRORS.GET_DATA))
				})
		})
	},

	getProjectSettings: function ({ rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: METHODS.GET,
				url: '/dataforce/project/settings',
				auth: true,
				query: input.query
			})
				.then(result => {
					resolve(result && result.data)
				}, () => {
					reject(new Error(ERRORS.GET_DATA))
				})
		})
	},

	setProjectSettings: function ({ rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: METHODS.POST,
				url: '/dataforce/project/settings',
				auth: true,
				body: input.body,
				query: input.query
			})
				.then(result => {
					resolve(result && result.status === 200)
				}, () => {
					reject(new Error(ERRORS.UPDATE))
				})
		})
	}
}

export default actions
