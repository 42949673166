import { mapState } from 'vuex'

import { isLoggedIn } from '@/function/auth.js'

export default {
	data () {
		return {
			startRequest: false
		}
	},

	computed: {
		...mapState({
			/* Конфигурация приложения */
			config: state => state.config,

			/* Профиль пользователя */
			profile: state => state.profile
		}),

		/* Наличие профиля */
		profileExists () {
			return !!(this.profile && this.profile.id)
		}
	},

	methods: {
		/* Запроc данных */
		async getData () {
			if (!this.startRequest && !this.profileExists && isLoggedIn()) {
				this.startRequest = true

				/* Запрос профиля */
				try {
					await this.$store.dispatch('getProfile')
				} catch (error) {
					this.$notify({ type: 'error', title: error })
				}

				/* Запрос списка меню */
				try {
					await this.$store.dispatch('getAsideList')
				} catch (error) {
					this.$notify({ type: 'error', title: error })
				}

				this.startRequest = false
			}
		}
	},

	watch: {
		async '$route' (to, from) {
			await this.getData()

			if (!isLoggedIn()) {
				this.$store.commit('getProfile', {})
			}
		}
	},

	async created () {
		/* Загрузка данных */
		if (!this.profileExists && isLoggedIn()) {
			await this.getData()
		}
	}
}
