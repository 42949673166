const getters = {
  tableStats: (state) => {
		if (state.stats) {
      const mainStats = state.stats
      const errorStats = state.stats.error_by_states
      const mainStatuses = ['done', 'in_progress']
      const arr = [...mainStatuses.map((key) => {
        return transformIntoLine(key, mainStats[key])
      }), ...Object.keys(errorStats).map((key) => {
        return transformIntoLine(key, errorStats[key])
      })]
			
      function transformIntoLine (key, value) {
        let name = ''
        let status = ''
        let isError = false

        switch (key) {
          case 'done':
            name = 'Статус = 1 - задание выполнено'
            status = 1
            break
          case 'in_progress':
            name = 'Статус = 255 - в кролике лежит'
            status = 255
            break
          case 'no_data':
            name = 'Статус = 2 - Ошибка - нет данных'
            status = 2
            isError = true
            break
          case 'auth':
            name = 'Статус = 3 - Ошибка авторизации'
            status = 3
            isError = true
            break
          case 'permission':
            name = 'Статус = 4 - Ошибка с доступами'
            status = 4
            isError = true
            break
          case 'token_fail':
            name = 'Статус = 5 - Ошибка - токен просрочен'
            status = 5 
            isError = true
            break
          case 'invalid_host':
            name = 'Статус = 6 - Ошибка - невалидный хост'
            status = 6
            isError = true
            break
        }

        return {
          ...value,
          name,
          status,
          isError: isError
        }
      }

      return arr
		}
		return []
	},
}

export default getters
