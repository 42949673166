function state () {
	return {
		projectsDF: {
			hasData: false,
			items: [],
			limit: 100,
			offset: 0,
			next: true
		},
		projectsSW: {
			hasData: false,
			items: [],
			limit: 100,
			offset: 0,
			next: true
		}
	}
}

export default state
