const state = {
	savedData: null,
	config: null,
	projects: null,
	analytics: null,
	topOutProjects: [],
	wsData: null,
	wsSavedData: null,
	notSavedQueriesCh: null
}

export default state
