import constants from '@/const/'

const actions = {
	getSegmentsCount: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/segments/count',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addSegmentsCount', result.data)
					resolve(true)
				} else {
				}
			}, () => {
			})
		})
	},
	getProjectSegmentsCount: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/segments/project/count',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addProjectSegmentsCount', result.data.items)
					commit('addProjectSegmentsTotal', result.data.total)
					resolve(true)
				} else {
				}
			}, () => {
			})
		})
	},
	getProblematicSegments: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/segments/entity/count',
				auth: true
			}).then(result => {
				if (result.data && result.data.items) {
					commit('addProblematicSegments', result.data.items)
					commit('addProblematicTotal', result.data.total)
					resolve(true)
				} else {
				}
			}, () => {
			})
		})
	}
}

export default actions
