import Vue from 'vue'

/* Notifications */
import notify from '@/components/notify/'

/* Запросы */
import VueResource from 'vue-resource'

/* Библиотека для работы с Cookies */
import VueCookie from 'vue-cookie'

/* Метаданные страниц */
import Meta from 'vue-meta'

import VueTippy, { TippyComponent } from 'vue-tippy'

import Vuelidate from 'vuelidate'

// Add additional themes.
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/themes/google.css'
import 'tippy.js/themes/translucent.css'
Vue.use(notify)
Vue.use(VueResource)
Vue.use(VueCookie)
Vue.use(Meta)
Vue.use(Vuelidate)

Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)

/* SVG sprites */
const __svg__ = {
	path: '../assets/svg/**/*.svg',
	name: './static/svg/public.[hash].svg'
}

require('webpack-svgstore-plugin/src/helpers/svgxhr')(__svg__)
